@media only screen and (max-width:1599px) {

}
 @media only screen and (max-width:1259px) {
.form-login h3 {
    font-size: 30px;
    text-align: center;
    margin: 10px 0 35px;
}
.form-login-col .form-control {
    border-radius: 0;
    border-color: #7B835E;
    font-size: 18px;
    padding: 15px 20px;
}
.form-login-col {
    margin-bottom: 30px;
}
.form-login {
    max-width: 610px;
    margin: 0 auto;
    padding: 35px 35px 10px;
    box-shadow: 0 0 20px rgba(0,0,0,12%);
}
.banner img {
    height: 300px;
    object-fit: cover;
}
.sec {
    padding: 60px 0;
}
.home-col h2 {
    font-size: 48px;
}
.home-col h3 {
    font-size: 32px;
}
.home-col p {
    margin-bottom: 30px;
}
.home-col {
    text-align: center;
    padding: 12% 0;
}
.modal-header {
    padding: 30px 30px 30px;
    border: none;
}
.modal-body {
    padding: 30px;
}

}

@media only screen and (max-width:991px) {
.form-login {
    margin: 10px auto;
    padding: 35px 35px 10px;
    box-shadow: 0 0 20px rgba(0,0,0,12%);
}
.home-col {
    padding: 33% 0;
}
.home-col h2 {
    font-size: 36px;
}
.home-col h3 {
    font-size: 24px;
}
.type-here input[type="text"], .golfer-form-col input[type="text"] {
    padding: 15px 20px;
    width: 100%;
    border-radius: 0;
    border: 1px solid #7B835E;
    margin: 0 0 15px;
}
.golfer-head-col h4 {
    font-size: 20px;
    font-weight: 700;
}
.modal-dialog {
    width: 80%;
}
.modal-title {
    font-size: 24px;
}
.modal-header .back {
    font-size: 26px;
}
.modal-header .close {
    padding: 0;
    margin: 0;
    color: #4C4847;
    font-size: 26px;
    opacity: 1;
}
.slot-time {
    font-size: 22px;
}
.slot-name {
    font-size: 20px;
}
.slot-name-det {
    font-size: 18px;
}
.slot-text {
    text-align: center;
    font-size: 16px;
    color: #4C4847;
    margin: 0 0 20px;
    padding-top: 10px;
}
}
@media only screen and (max-width:767px) {
.banner img {
    height: 250px;
    object-fit: cover;
}
.form-login h3 {
    font-size: 24px;
    text-align: center;
    margin: 5px 0 30px;
}
.form-login {
    margin: 10px auto;
    padding: 30px 25px 10px;
    box-shadow: 0 0 20px rgba(0,0,0,12%);
}
.copy-r {
    font-size: 14px;
}
.sec {
    padding: 30px 0;
}
.home-col h2 {
    font-size: 24px;
    margin-bottom: 5px;
}
.home-col h3 {
    font-size: 18px;
    margin-bottom: 5px;
}
body {
    font-size: 16px;
}
.home-col {
    padding: 31% 0;
}
.golf-top h2 {
    font-size: 30px;
}
.golf-head h4 {
    font-size: 20px;
}
.golf-box-col p {
    font-size: 18px;
}
.golf-box-col a {
    font-size: 18px;
}
.confirmation-text span {
    font-size: 19px;
    display: block;
}
.confirmation-text {
    font-size: 14px;
}
.confirmation-time {
    font-size: 19px;
}
.confirmation-box a {
    font-size: 16px;
}
.confirmation-box .confirmation-n a {
    color: #4C4847;
    text-decoration: underline;
    font-size: 14px;
}
.confirmation-n {
    padding-top: 15px;
    margin: 0;
}
.confirmation-box {
    margin: 13.5% auto;
    padding: 25px;
}
.golfer-head-col {
    margin-bottom: 15px;
}
.golfer-box {
    padding: 25px;
}
.golfer-form-col {
    margin-bottom: 10px;
}
.golfer-check label:before {
    padding: 10px;
    margin-right: 10px;
}
.golfer-check label {
    font-size: 14px;
}
.modal-header {
    padding: 20px 17px 0;
    border: none;
}
.modal-title {
    font-size: 20px;
}
.modal-header .close {
    padding: 0;
    margin: 0;
    color: #4C4847;
    font-size: 22px;
    opacity: 1;
}
.modal-header .back {
    font-size: 22px;
    margin: 0;
    padding: 0;
}
.slot-time {
    font-size: 20px;
}
.slot-name {
    font-size: 18px;
}
.slot-name-det {
    font-size: 16px;
}
.slot-text {
    font-size: 14px;
}
.modal-body {
    padding: 20px;
}
.type-here {
    margin: 0 0 10px;
}
.gf-box {
    margin-bottom: 50px;
}
}
@media only screen and (max-width:567px) {

}
@media only screen and (max-width:467px) {
.form-login-col .form-control {
    font-size: 18px;
    padding: 10px 20px;
}
.custom-btn {
    font-size: 18px;
    font-weight: 500;
    min-width: 170px;
    padding: 10px 18px;
    letter-spacing: 1px;
}
.copy-r {
    font-size: 12px;
}
.banner img {
    height: 200px;
    object-fit: cover;
}
.golf-top h2 {
    font-size: 24px;
}
body {
    font-size: 14px;
}
.confirmation-text {
    font-size: 13px;
}


}
@media only screen and (max-width:367px) {
}


@media only screen and (max-width:1599px) {
    .starter-date {
        font-size: 32px;
    }
    .starter-number {
        font-size: 36px;
        width: 66px;
        height: 66px;
        line-height: 66px;
    }
    .starter-btn {
        font-size: 18px;
        padding: 10px;
    }
    .starter-form-head h4 {
        width: 60%;
        font-size: 24px;
    }
    ul.starter-list li {
        padding: 10px 15px;
        font-size: 18px;
    }
    .starter-form-col label {
        font-size: 18px;
    }
    .starter-form-col input[type="text"] {
        font-size: 1.5em;
        padding: 11px 15px;
    }
    
    .starter-form-col {
        width: 33.33%;
        margin-bottom: 0;
    }
    
    }
     @media only screen and (max-width:1259px) {
    .form-login h3 {
        font-size: 30px;
        text-align: center;
        margin: 10px 0 35px;
    }
    .form-login-col .form-control {
        border-radius: 0;
        border-color: #7B835E;
        font-size: 18px;
        padding: 15px 20px;
    }
    .form-login-col {
        margin-bottom: 30px;
    }
    .form-login {
        max-width: 610px;
        margin: 0 auto;
        padding: 35px 35px 10px;
        box-shadow: 0 0 20px rgba(0,0,0,12%);
    }
    .banner img {
        height: 300px;
        object-fit: cover;
    }
    .sec {
        padding: 60px 0;
    }
    .home-col h2 {
        font-size: 48px;
    }
    .home-col h3 {
        font-size: 32px;
    }
    .home-col p {
        margin-bottom: 30px;
    }
    .home-col {
        text-align: center;
        padding: 12% 0;
    }
    .modal-header {
        padding: 30px 30px 0;
        border: none;
    }
    .modal-body {
        padding: 30px;
    }
    .log-out-btn {
        min-width: 105px;
        text-align: right;
    }
    /*.header-logo {
        margin: 0 100px;
    }*/
    .menu-box-col a span {
        font-size: 20px;
        padding-top: 13px;
    }
    .menu-box-col a {
        margin: 10px;
        padding: 23px;
    }
    .user-management td {
        font-size: 18px;
        padding: 15px 20px;
    }
    .user-management table thead td {
        font-size: 20px;
    }
    .starter-form-left {
        width: 200px;
        padding: 15px;
    }
    .starter-date {
        font-size: 24px;
    }
    .starter-number {
        font-size: 28px;
        width: 50px;
        height: 50px;
        line-height: 50px;
    }
    .starter-btn {
        font-size: 16px;
        padding: 10px;
    }
    .starter-form-right {
        width: calc(100% - 200px);
        padding: 15px;
    }
    .starter-form-head h4 {
        width: 60%;
        font-size: 18px;
    }
    ul.starter-list {
        margin: 0 -10px;
        padding: 0;
    }
    ul.starter-list li {
        padding: 10px;
        font-size: 16px;
    }
    .starter-form-head > span img {
        height: 30px;
    }
    .starter-form-head {
        margin: 0 0 15px;
        min-height: 40px;
    }
    .starter-form-col label {
        font-size: 16px;
    }
    .starter-form-col input[type="text"] {
        font-size: 1.5em;
        padding: 10px 15px;
    }
    .starter-form-head > span {
        font-size: 36px;
        line-height: 36px;
        cursor: pointer;
    }
    }
    
    @media only screen and (max-width:991px) {
    .form-login {
        margin: 10px auto;
        padding: 35px 35px 10px;
        box-shadow: 0 0 20px rgba(0,0,0,12%);
    }
    .home-col {
        padding: 33% 0;
    }
    .home-col h2 {
        font-size: 36px;
    }
    .home-col h3 {
        font-size: 24px;
    }
    .type-here input[type="text"], .golfer-form-col input[type="text"] {
        padding: 15px 20px;
        width: 100%;
        border-radius: 0;
        border: 1px solid #7B835E;
        margin: 0 0 15px;
    }
    .golfer-head-col h4 {
        font-size: 20px;
        font-weight: 700;
    }
    .modal-dialog {
        width: 80%;
    }
    .modal-title {
        font-size: 24px;
    }
    .modal-header .back {
        font-size: 26px;
    }
    .modal-header .close {
        padding: 0;
        margin: 0;
        color: #4C4847;
        font-size: 26px;
        opacity: 1;
    }
    .slot-time {
        font-size: 22px;
    }
    .slot-name {
        font-size: 20px;
    }
    .slot-name-det {
        font-size: 18px;
    }
    .slot-text {
        text-align: center;
        font-size: 16px;
        color: #4C4847;
        margin: 0 0 20px;
        padding-top: 10px;
    }
    /*.header-logo {
        margin: 0 75px;
    }*/
    #starter-form-col-email {
        width: auto !important;
     }
    .log-out-btn span {
        display: none;
    }
    .golf-top h2 {
        font-size: 30px;
    }
    .starter-form-left {
        width: 170px;
        padding: 15px;
    }
    .starter-form-right {
        width: calc(100% - 170px);
        padding: 15px;
    }
    }
    @media only screen and (max-width:767px) {
    .banner img {
        height: 250px;
        object-fit: cover;
    }
    .form-login h3 {
        font-size: 24px;
        text-align: center;
        margin: 5px 0 30px;
    }
    .form-login {
        margin: 10px auto;
        padding: 30px 25px 10px;
        box-shadow: 0 0 20px rgba(0,0,0,12%);
    }
    .copy-r {
        font-size: 14px;
    }
    .sec {
        padding: 30px 0;
    }
    .home-col h2 {
        font-size: 24px;
        margin-bottom: 5px;
    }
    .home-col h3 {
        font-size: 18px;
        margin-bottom: 5px;
    }
    body {
        font-size: 16px;
    }
    .home-col {
        padding: 31% 0;
    }
    .golf-top h2 {
        font-size: 30px;
    }
    .golf-head h4 {
        font-size: 20px;
    }
    .golf-box-col p {
        font-size: 18px;
    }
    .golf-box-col a {
        font-size: 18px;
    }
    .confirmation-text span {
        font-size: 19px;
        display: block;
    }
    .confirmation-text {
        font-size: 14px;
    }
    .confirmation-time {
        font-size: 19px;
    }
    .confirmation-box a {
        font-size: 16px;
    }
    .confirmation-box .confirmation-n a {
        color: #4C4847;
        text-decoration: underline;
        font-size: 14px;
    }
    .confirmation-n {
        padding-top: 15px;
        margin: 0;
    }
    .confirmation-box {
        margin: 13.5% auto;
        padding: 25px;
    }
    .golfer-head-col {
        margin-bottom: 15px;
    }
    .golfer-box {
        padding: 25px;
    }
    .golfer-form-col {
        margin-bottom: 10px;
    }
    .golfer-check label:before {
        padding: 10px;
        margin-right: 10px;
    }
    .golfer-check label {
        font-size: 14px;
    }
    .modal-header {
        padding: 20px 17px 0;
        border: none;
    }
    .modal-title {
        font-size: 20px;
    }
    .modal-header .close {
        padding: 0;
        margin: 0;
        color: #4C4847;
        font-size: 22px;
        opacity: 1;
    }
    .modal-header .back {
        font-size: 22px;
        margin: 0;
        padding: 0;
    }
    .slot-time {
        font-size: 20px;
    }
    .slot-name {
        font-size: 18px;
    }
    .slot-name-det {
        font-size: 16px;
    }
    .slot-text {
        font-size: 14px;
    }
    .modal-body {
        padding: 20px;
    }
    .type-here {
        margin: 0 0 10px;
    }
    .gf-box {
        margin-bottom: 50px;
    }
    .golfer-form-col textarea {
        padding: 15px 20px;
        min-height: 118px;
    }
    .header-logo {
        margin: 0 20px;
        max-width: 70px;
    }
    .golfer-check input:checked + label:after {
        top: 3px;
        left: 8px;
        width: 6px;
        height: 12px;
    }
    .menu-box-col {
        padding: 0;
    }
    .user-management td {
        font-size: 13px;
        padding: 8px 10px;
    }
    .user-management table thead td {
        font-size: 14px;
    }
    /*.af-login {
        padding-right: 40px;
    }*/
    .starter-form-left {
        width: 100%;
        padding: 15px;
    }
    .starter-form-right {
        width: 100%;
        padding: 15px;
    }
    ul.starter-list li {
        padding: 10px;
        font-size: 14px;
    }
    .starter-form-col label {
        font-size: 14px;
    }
    ul.starter-list {
        flex-wrap: wrap;
    }
    .starter-form-col {
        width: 50%;
        margin-bottom: 0;
    }
    .select-cor {
        position: absolute;
        left: 15px;
        top: 60px;
    }
    .select-date {
        position: absolute;
        right: 15px;
        top: 60px;
    }
    body {
        padding-top: 100px;
    }
    }
    @media only screen and (max-width:567px) {
    
    }
    @media only screen and (max-width:467px) {
    .form-login-col .form-control {
        font-size: 18px;
        padding: 10px 20px;
    }
    .custom-btn {
        font-size: 18px;
        font-weight: 500;
        min-width: 170px;
        padding: 10px 18px;
        letter-spacing: 1px;
    }
    .copy-r {
        font-size: 12px;
    }
    .banner img {
        height: 200px;
        object-fit: cover;
    }
    .golf-top h2 {
        font-size: 24px;
    }
    body {
        font-size: 14px;
    }
    .confirmation-text {
        font-size: 13px;
    }
    .custom-btn {
        font-size: 16px;
        min-width: 150px;
        padding: 8px 18px;
    }
    
    }
  @media only screen and (max-width:367px) {
    }
   @media only screen and (max-width: 450px) {
       .bodyContainer {
           width: 100%;
       }
   }

   @media only screen and (max-width: 450px) {
       .rightHalf {
           width: 10%;
        }
   }

   @media only screen and (max-width: 450px) {
       .footer_reward {
           flex-direction: column;
        }
   }

   @media only screen and (max-width: 450px) {
       .center_modal {
           width: 95% !important;
       }
   }

   .modal_title_container {
       font-size: 18px !important;
   }

   @media only screen and (min-width: 768px) {
       .reward_container {
           max-width: 750px;
       }
   }

   @media only screen and (min-width: 992px) {
       .reward_container {
           max-width: 970px;
       }
   }

   @media only screen and (min-width: 1200px) {
       .reward_container {
           max-width: 1170px;
       }
   }

   @media only screen and (min-width: 768px) {
       .center-align .input_wrapper {
           margin-left: 25px;
       }
   }

   @media only screen and (min-width:320px) {
       .center-align {
           flex-direction: column;
           align-items: flex-start;
           margin-bottom: 10px;
        }
   }

   @media only screen and (min-width: 768px) {
       .center-align {
           flex-direction: row;
           margin-left: 19px;
           margin-right: 19px;
           margin: 19px;
           align-items: baseline;
       }
   }